<template>
    <!-- index.vue -->

    <v-app
        :style="{
            background: $vuetify.theme.themes[theme].background,
            color: $vuetify.theme.themes[theme].color,
        }"
        :class="{
            'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
            'sidebar-close': !getThemeMode.verticalSidebarDrawer,
        }"
    >
        <side-bar>
            <!-- -->
        </side-bar>

        <app-bar class="">
            <!-- -->
        </app-bar>
        <!-- <sub-header>

</sub-header> -->
        <!-- Sizes your content based upon application components -->

        <base-view />

        <c-snackbar />
        <loading />
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
        BaseView: () => import("@/layouts/basic-home/View"),
        SideBar: () => import("@/layouts/basic-home/Sidebar"),
        AppBar: () => import("@/layouts/basic-home/AppBar"),
        // SubHeader: () => import("./SubHeader"),
        CSnackbar: () => import('@/components/common/CSnackbar'),
        Loading: () => import('@/components/common/Loading')
    },

    computed: {
        ...mapGetters(["getThemeMode"]),

        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
