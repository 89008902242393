var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:{
            'app-admin-wrap-layout-1 sidebar-mini': _vm.getThemeMode.verticalSidebarMini,
            'sidebar-close': !_vm.getThemeMode.verticalSidebarDrawer,
        },style:({
            background: _vm.$vuetify.theme.themes[_vm.theme].background,
            color: _vm.$vuetify.theme.themes[_vm.theme].color,
        })},[_c('side-bar'),_c('app-bar',{}),_c('base-view'),_c('c-snackbar'),_c('loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }